<template>
  <div class="application">
    <div class="page-title clearfix">
      <div class="float-left">
        <span>{{ $t("nav.service") }}</span>
        <!-- <span class="total-num">4</span> -->
      </div>
      <div class="float-right">
        <button
          v-if="user.role === '0'"
          type="button"
          class="btn btn-danger btn-sm"
          @click="goEditPage"
        >
          {{ $t("service.create") }}
        </button>
      </div>
    </div>
    <div class="application-content">
      <div class="row">
        <div
          class="
            col-xxl-4 col-xl-4 col-lg-6 col-md-8 col-sm-12
            rounded
            animated
            fadeInLeft
          "
          v-for="item in application"
          :key="item.id"
        >
          <div class="card mb-4 shadow">
            <div class="card-body">
              <div class="button-wrapper" v-if="user.role === '0'">
                <span class="btn-edit mr5" @click="editApplication(item.id)"
                  ><i class="el-icon-edit-outline"></i
                ></span>
                <span
                  class="btn-delete"
                  @click="handleDeleteApplication(item.id)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
              <div class="base-info">
                <div class="img-box">
                  <img :src="item.appicon_url" alt="" />
                </div>
                <div class="base-info-right">
                  <p class="service-title">{{ item.app_name }}</p>
                  <div class="sub-title">{{ item.introduction }}</div>
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="modality">
                      <span>{{ $t("project.modality") }}：</span>
                      <span class="bg-primary bg-span">{{
                        item.modality
                      }}</span>
                      <dfn
                        v-if="item.multimodality == 1 && item.app_json.subtypes"
                      >
                        <i v-for="(sub, i) in item.app_json.subtypes" :key="i"
                          ><span
                            v-if="sub.modality != item.modality"
                            class="bg-primary bg-span"
                            >{{ sub.modality }}</span
                          ></i
                        >
                      </dfn>
                    </div>
                    <div class="series mt-1">
                      <span>{{ $t("project.ser_type") }}：</span>
                      <dfn v-if="item.series && item.series != ''">
                        <span
                          v-for="seria in item.series"
                          :key="seria"
                          class="bg-success bg-span mr-1"
                          >{{ seria }}</span
                        ></dfn
                      >
                      <dfn v-for="(sub, i) in item.app_json.subtypes" :key="i">
                        <i v-for="(s, j) in sub.series" :key="j"
                          ><span class="bg-secondary bg-span mr-1">{{
                            s
                          }}</span></i
                        >
                      </dfn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="service-desc">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getApplication, delApplicationRequest } from "../../api/api";
import { MessageBox, Loading } from "element-ui";
export default {
  data() {
    return {
      application: [],
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  created() {
    this.getApplicationList();
  },
  methods: {
    goEditPage() {
      this.$router.push("/application/edit");
    },
    //获取服务应用列表
    async getApplicationList() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let res = await getApplication();
        if (res.status === 200) {
          this.application = res.data.length > 0 ? res.data : [];
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    editApplication: function (appId) {
      this.$router.push({ path: "/application/edit", query: { id: appId } });
    },
    //删除服务应用打开确认弹窗
    handleDeleteApplication(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteApplication(id);
        })
        .catch(() => {});
    },
    //删除服务应用
    async deleteApplication(id) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let res = await delApplicationRequest({ id: id });
        if (res.status === 200) {
          _self.$helper.successNotify(res.message);
          _self.getApplicationList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
  },
};
</script>

<style lang="less">
.application-content {
  padding-top: 30px;
  .card {
    height: 272px;
    min-width: 413px;
    overflow: hidden;
  }
  .card-body {
    position: relative;
    .button-wrapper {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 16px;
      span {
        cursor: pointer;
      }
      .btn-edit {
        color: #007bff;
      }
      .btn-delete {
        color: #dc3545;
      }
    }
  }
  .base-info {
    display: flex;
    margin-bottom: 25px;
    .img-box {
      margin-right: 30px;
      width: 100px;
      height: 100px;
      background: #e5e5e5;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        // border-radius: 10px;
      }
    }
    .base-info-right {
      flex: 1;
      dfn,
      i {
        font-style: normal;
      }
    }
    p {
      margin-bottom: 0;
    }
    .service-title {
      height: 34px;
      font-size: 20px;
      color: #212528;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 190px;
    }
    .sub-title {
      height: 32px;
      line-height: 1.3;
      margin-bottom: 5px;
      font-size: 12px;
      color: #6c757d;
    }
    .modality {
      margin-right: 10px;
    }
    .bg-span {
      display: inline-block;
      padding: 0 5px;
      height: 18px;
      line-height: 18px;
      border-radius: 4px;
      color: #fff;
    }
  }
  .service-desc {
    padding: 5px;
    height: 100px;
    border: 1px solid #dee2e6;
    overflow-y: auto;
    color: #6c757d;
  }
}
</style>
